import {
  Box,
  Flex,
  FormLabel,
  FormControl,
  Input,
  FormErrorMessage,
  FormErrorIcon,
  Center,
  Text,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { InputInterface, TextAreaBox } from "../../components/micro/TextInput";
import UploadItem from "../../components/minors/UploadFile";
import { useState } from "react";
import ButtonInterface from "../../components/minors/ButtonInterface";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
//@ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
import { validationSchema } from "../../validations/schemas/index.schema";
import axios from "axios";
import ModalLayout from "../../Layouts/ModalLayout";
window.Buffer = window.Buffer || require("buffer").Buffer;

const IndexComponent = () => {
  const [idImage, setIdImage] = useState<string>("");
  const [imageTile, setImageTitle] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const styles = {
    fontWeight: "500",
    color: "#344054",
    fontSize: "16.2px",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  };

  const handleUpload = (e: any) => {
    const file = e.target.files[0];
    setImageTitle(file.name.split(".")[0]);
    setIdImage(file);
  };

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    interest: "",
    yearOfRelevant: "",
    relevantSkill: "",
    interestWithUs: "",
    linkedInUrl: "",
    additionComment: "",
  };

  const onSubmit = async (values: any) => {
    try {
      setLoader(true);
      const postData = await axios.post(
        "https://gogeeper.co.uk/api/v1/gogeeper/careers/create",
        { ...values, uploadCV: idImage },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const response = postData;
      if (response.status === 200) {
        onOpen();
      }
    } catch (err) {
      //@ts-ignore
      const errMsg = err?.response?.data?.message;
      toast({
        status: "error",
        description: errMsg,
        position: "top",
      });
    } finally {
      setLoader(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const handleCaptcha = () => {
    console.log("handled captcha ");
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box w={["100%", "100%", "40%"]} mx={"auto"} my={"3em"}>
        <Box>
          <Flex gap={"1em"} flexDir={["column", "row"]}>
            <FormControl isInvalid={formik.errors.first_name ? true : false}>
              <FormLabel className="asterisk" sx={styles}>
                First Name
              </FormLabel>
              <Input
                type={"text"}
                placeholder={"First Name"}
                name={"first_name"}
                border={"1px solid #EDEDED"}
                py={"1.5em"}
                boxShadow={"0px 1px 2px rgba(16, 24, 40, 0.05)"}
                borderRadius={"8px"}
                onChange={formik.handleChange}
              />

              <FormErrorMessage>
                <FormErrorIcon />
                {formik.errors.first_name}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formik.errors.last_name ? true : false}>
              <FormLabel className="asterisk" sx={styles}>
                Last Name
              </FormLabel>
              <Input
                placeholder={"Last Name"}
                name={"last_name"}
                border={"1px solid #EDEDED"}
                py={"1.5em"}
                boxShadow={"0px 1px 2px rgba(16, 24, 40, 0.05)"}
                borderRadius={"8px"}
                onChange={formik.handleChange}
              />
              <FormErrorMessage>{formik.errors.last_name}</FormErrorMessage>
            </FormControl>
          </Flex>

          <InputInterface
            w={"100%"}
            label={"Email"}
            placeholder={"you@gmail.com"}
            name={"email"}
            type={"email"}
            onChange={formik.handleChange}
            isInvalid={formik.errors.email ? true : false}
            isError={formik.errors.email}
          />

          <FormControl
            isInvalid={formik.errors.phone_number ? true : false}
            my={"1.5em"}
          >
            <PhoneInput
              searchPlaceholder="search"
              inputProps={{
                name: "phone_number",
                required: true,
                autoFocus: true,
              }}
              country="ng"
              value={formik.values.phone_number} // Set the value prop to the corresponding formik field
              onChange={(value) => formik.setFieldValue("phone_number", value)} // Update the formik field value on change
            />

            <FormErrorMessage>
              <FormErrorIcon />
              {formik.errors.phone_number}
            </FormErrorMessage>
          </FormControl>

          <InputInterface
            w={"100%"}
            label={"Position of interest"}
            placeholder={"Chief Executive Officer "}
            name={"interest"}
            type={"text"}
            onChange={formik.handleChange}
            isInvalid={formik.errors.interest ? true : false}
            isError={formik.errors.interest}
          />

          <InputInterface
            w={"100%"}
            label={"Years of Relevant Experience "}
            placeholder={"3 years "}
            name={"yearOfRelevant"}
            type={"number"}
            onChange={formik.handleChange}
            isInvalid={formik.errors.yearOfRelevant ? true : false}
            isError={formik.errors.yearOfRelevant}
          />

          <Box my={"1.5em"}>
            <FormControl isInvalid={formik.errors.relevantSkill ? true : false}>
              <TextAreaBox
                label={
                  "Briefly describe your relevent skills and qualifications "
                }
                placeholder={"Your relevant skills and qualification  "}
                name={"relevantSkill"}
                //@ts-ignore
                onChange={formik.handleChange}
              />
              <FormErrorMessage>
                <FormErrorIcon /> {formik.errors.relevantSkill}
              </FormErrorMessage>
            </FormControl>
          </Box>

          <TextAreaBox
            label={"What interests you about working at Go-Geeper "}
            placeholder={"Your interest in working with us"}
            name={"interestWithUs"}
            //@ts-ignore
            onChange={formik.handleChange}
          />

          <UploadItem
            fileLabel={"upload"}
            label={"Upload CV"}
            name={"userIdentityDocument"}
            item={imageTile}
            onChange={(e: any) => {
              handleUpload(e);
            }}
          />

          <InputInterface
            w={"100%"}
            label={"Portfolio/Website link"}
            placeholder={"https://www.yourdomain.com "}
            name={"experience"}
            type={"text"}
            onChange={formik.handleChange}
            isInvalid={formik.errors.email ? true : false}
            isError={formik.errors.email}
          />

          <InputInterface
            w={"100%"}
            label={"Link to Linkedin profile "}
            placeholder={"https://www.linkedin.com/in/yourname"}
            name={"linkedInUrl"}
            type={"text"}
            onChange={formik.handleChange}
            isInvalid={formik.errors.linkedInUrl ? true : false}
            isError={formik.errors.linkedInUrl}
          />

          <TextAreaBox
            name={"additionComment"}
            label={"Additional Comments or Questions"}
            placeholder={"Comments or Questions"}
            //@ts-ignore
            onChange={formik.handleChange}
          />
          {/* <Center>
            <ReCAPTCHA
              sitekey="6LfP6PwlAAAAAM-xtYwFHq-rRrjQLHOUhHdQX6ZH"
              onChange={handleCaptcha}
            />
          </Center> */}

          <ButtonInterface
            my={"1em"}
            w={"100%"}
            type={"submit"}
            loading={loader}
          >
            Submit
          </ButtonInterface>
        </Box>
      </Box>

      <ModalLayout isOpen={isOpen} onClose={onClose}>
        <Center>
          <Box>
            <Box my={"1.5em"}>
              <Center>
                <svg
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_168_25696)">
                    <path
                      d="M64 0H0V64H64V0Z"
                      fill="white"
                      fillOpacity="0.01"
                    />
                    <path
                      d="M31.9997 58.6666C39.3634 58.6666 46.0301 55.6818 50.8558 50.8561C55.6815 46.0303 58.6663 39.3636 58.6663 31.9999C58.6663 24.6362 55.6815 17.9695 50.8558 13.1437C46.0301 8.31802 39.3634 5.33325 31.9997 5.33325C24.6359 5.33325 17.9693 8.31802 13.1435 13.1437C8.31778 17.9695 5.33301 24.6362 5.33301 31.9999C5.33301 39.3636 8.31778 46.0303 13.1435 50.8561C17.9693 55.6818 24.6359 58.6666 31.9997 58.6666Z"
                      fill="#D1FADF"
                      stroke="#ECFDF3"
                      strokeWidth="4"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21.333 32L29.333 40L45.333 24"
                      stroke="#039855"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <circle
                      cx="52.6959"
                      cy="56.6965"
                      r="2"
                      transform="rotate(152.558 52.6959 56.6965)"
                      fill="#039855"
                    />
                    <circle
                      cx="57.7613"
                      cy="52.7617"
                      r="3"
                      transform="rotate(-17.4416 57.7613 52.7617)"
                      fill="#039855"
                    />
                    <circle
                      cx="60.5075"
                      cy="46.5075"
                      r="2"
                      transform="rotate(-17.4416 60.5075 46.5075)"
                      fill="#039855"
                    />
                    <circle
                      cx="3.30451"
                      cy="17.6945"
                      r="2"
                      transform="rotate(150.558 3.30451 17.6945)"
                      fill="#039855"
                    />
                    <circle
                      cx="6.16049"
                      cy="11.6561"
                      r="3"
                      transform="rotate(-19.4416 6.16049 11.6561)"
                      fill="#039855"
                    />
                    <circle
                      cx="10.7558"
                      cy="7.24004"
                      r="2"
                      transform="rotate(-19.4416 10.7558 7.24004)"
                      fill="#039855"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_168_25696">
                      <rect width="64" height="64" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Center>
            </Box>
            <Text fontWeight={"bold"} fontSize={"18px"} textAlign={"center"}>
              Thank you for applying to work at Go-Geeper
            </Text>
            <Text textAlign={"center"} my={"0.5em"}>
              We will review your application and contact you as soon as
              possible.
            </Text>
            <ButtonInterface
              onClick={(e: any) =>
                (window.location.href = "https://home.gogeeper.com/")
              }
              my={"2em"}
              w={"100%"}
            >
              Explore Go-Geeper
            </ButtonInterface>
          </Box>
        </Center>
      </ModalLayout>
    </form>
  );
};

export default IndexComponent;
